<template>
<div>
  <v-skeleton-loader
    :loading="loadingTable"
    :transition="'fade-transition'"
    height="500"
    type="table"
  >
    <v-data-table
      :headers="getHeaders(typesNotHeader)"
      :items="dataTable"
      :items-per-page="dataTable.length"
      hide-default-footer
      dense
      item-class="teste"
      :search="searchLocal"
      :mobile-breakpoint="300"
      class="box-table"
      :custom-filter="customFilter"
    >
      <template
        v-slot:body="{ items }"
      >

       <tbody>
          <tr>
            <th>Total</th>
            <th class="text-end">{{ getTotal(items, 'media') | currencyNoSymbol }}</th>
            <th class="text-end">{{ getTotal(items, 'anterior') | currencyNoSymbol }}</th>
            <th class="text-end">{{ getTotal(items, 'atual') | currencyNoSymbol }}</th>
          </tr>
         
          <tr
            v-for="item in items"
            :key="item.entity_id"
            :class="{ cancelado: isDeleted(item) }"
            class="box-table-row"
            @click="maisDetalhes(item)"
          >
            <td>
              <v-btn
                elevation="1"
                plain
                raised
                small
                tile
                :color="getColor(item)"
                :dark="isDark(item)"
                @click.stop="opemModalEntidade(item)"
                :height="20"
              >
                <v-tooltip top v-if="isDeleted(item)">
                  <template v-slot:activator="{ on, attrs }">
                    <span 
                      v-bind="attrs"
                      v-on="on"
                      class="text-truncate text-name"
                    >
                      {{ item.cod }} <span class="mobile">- {{ item.entity_name }}</span>
                    </span>
                  </template>
                  <span>{{ item.entity_deleted | datas('DD/MM/YYYY') }}</span>
                </v-tooltip>
                <span v-else class="text-truncate text-name">
                  {{ item.cod }}
                  <span class="mobile">- {{ item.entity_name }}</span>
                </span>
              </v-btn>
            </td>
            <td class="text-end">{{ item.media | currencyNoSymbol }}</td>
            <td class="text-end">{{ item.vendas_ant | currencyNoSymbol }}</td>
            <td class="text-end">{{ item.vendas | currencyNoSymbol }}</td>
            <!-- <td class="text-end">{{ item.pagto | currencyNoSymbol }}</td> -->
            <td class="text-end">
              <v-btn
                fab
                :width="20"
                :color="variables.colorPrimary"
                class="btn-remove"
                dark
              >
                <v-icon :size="14">mdi-plus</v-icon>
              </v-btn>

            </td>
          </tr>

          <tr>
            <!-- <th>Total</th> -->
            <!-- <th class="text-end mobile">{{ totais.vendas_ant | currencyNoSymbol }}</th>
            <th class="text-end">{{ totais.vendas | currencyNoSymbol }}</th>
            <th class="text-end mobile">{{ totais.caixa | currencyNoSymbol }}</th>
            <th class="text-end mobile">{{ totais.pagto | currencyNoSymbol }}</th>
            <th class="text-end">{{ totais.balance | currencyNoSymbol }}</th> -->
          </tr>
        </tbody>
      </template>
    </v-data-table>
        
        
  </v-skeleton-loader>

  <v-card class="mt-4">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <ul class="list">
            <li class="list-item">
              <span class="list-item-circle"></span>
              maior ou igual 10%
            </li>
            <li class="list-item">
              <span class="list-item-circle"></span>
              maior ou igual 0%
            </li>
            <li class="list-item">
              <span class="list-item-circle"></span>
              menor que 0%
            </li>
          </ul>
        </v-col>

        <v-col cols="12" sm="8">
          <div class="d-flex mb-2">
            <strong class="mr-2">Média:</strong> São a média de vendas das últimas 3 semanas
          </div>
          <div class="d-flex mb-2">
            <strong class="mr-2">Anterior:</strong> Total vendas do dia anterior
          </div>
          <div class="d-flex mb-2">
            <strong class="mr-2">Atual:</strong> Total vendas do dia
          </div>
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
export default {
  name: 'TabelaRelatorioGecom',
  props: {
    dataTable: {
      type: Array,
      default: () => {},
      required: true
    },

    supervisor: {
      type: Object,
      default: () => {}
    },

    promotor: {
      type: Object,
      default: () => {}
    },

    loadingTable: {
      type: Boolean,
      default: false
    },

    typesNotHeader: {
      type: Array,
      default: () => []
    },

    typeGecom: {
      type: Number,
      default: null
    },

    configGecom: {
      type: Object,
      default: () => {}
    },

    search: {
      type: String,
      required: true
    },

    date: {
      type: String,
      required: true
    }
  },
  data: () => ({
    searchLocal: ''
  }),

  mounted () {
    this.searchLocal = this.search
  },

   watch: {
     'search' (val) {
       this.searchLocal = val
     }
   },

  computed: {
    headersData () {
      return [
        { align: 'start', width: 50, class: 'table-header', cellClass: 'text-truncate', text: this.getTextHeaderTypeGecom(this.typeGecom), value: 'cod' },
        { filter: false, align: 'end', class: 'table-header', text: 'Media', value: 'media' },
        { filter: false, align: 'end', class: 'table-header', text: 'Anterior', value: 'vendas_ant' },
        { filter: false, align: 'end', class: 'table-header', text: 'Atual', value: 'vendas' },
        { filter: false, align: 'end', class: 'table-header', text: '', value: '' },
      ]
    },

    variables: () => variables
  },

  methods: {
    formatName (item) {
      return item.json_data ? `(${item.json_data.cod}) ${item.json_data.name}` : item.name
    },

    getHeaders (typesNotHeader) {
      return this.headersData.filter(item => {
        return !typesNotHeader.includes(item.value)
      })
    },

    openProposta(val) {
      this.$emit('openProposta', val)
    },

    opemModalEntidade(val) {
      this.$emit('openModalEntidade', val)
    },

    getTextHeaderTypeGecom (typeGecom) {
      switch (typeGecom) {
        case null:
          return 'Supervisor'
        case 2:
          return 'Vendedor'
        case 3:
        case 4:
          return 'RV'
        default:
          return 'RV'
      }
    },

    getColor (item) {
      const vendas = parseInt(item.media)
      const vendasAnterior = parseInt(item.vendas_ant)
      if (!vendas) return this.variables.colorError
      if (!vendasAnterior) return this.variables.colorError


      const percentage = (vendasAnterior / vendas) * 100
      console.log(percentage)
      const { success, warning } = this.configGecom.percentageSales
      if (percentage >= success) {
        return this.variables.colorSecondary
      }

      if (percentage >= warning) {
        return this.variables.colorWarning
      }

      return this.variables.colorError
    },

    isDark (item) {
     const vendas = parseInt(item.media)
      const vendasAnterior = parseInt(item.vendas_ant)
      if (!vendas) return true
      if (!vendasAnterior) return true

      const percentage = (vendasAnterior / vendas) * 100
      const { success, warning } = this.configGecom.percentageSales
      if (percentage >= success) {
        return true
      }

      if (percentage >= warning) {
        return false
      }

      return true
    },

    isDeleted (data) {
      if (data.entity_deleted > '1970-01-01'){
        return true
      }

      return false
    },

    customFilter (value, search, item) {
      console.log(value, search, item)
      return item != null &&
        search != null &&
        item.cod.toString().indexOf(search) !== -1
    },


    maisDetalhes (item) {
      if (item.parent_path.split('.').length < 4) {
        this.$emit('getMaisDetalheGecom', item.parent_path)
      } else {
        this.$router.push({ name: 'dados-revenda-gecom', params: { entity: item, date: this.date } })
      }
    },

    getTotal (items, type) {
      switch(type) {
        case 'media':
          return items.reduce((acc, { media }) => acc + parseFloat(media), 0)
        case 'anterior':
          return items.reduce((acc, { vendas_ant }) => acc + parseFloat(vendas_ant), 0)
        case 'atual':
          return items.reduce((acc, { vendas }) => acc + parseFloat(vendas), 0)
        default:
          return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .text-name {
    display: inline-block;
    line-height: 0.9;

    @media (min-width: 420px) {
      width: 115px!important;
    } 
  }

  .cadastro-usuario >>> .v-data-table__wrapper table {
    min-width: auto;
  }

  .box-table::v-deep {

    table {
      min-width: 400px;
    }

    .cancelado {
      color: #F00;
    }

    .mobile {
      @media (max-width: 420px) {
        display: none;
      } 
    }

    .v-btn {
      height: 20px !important;

    }
  }

  .list {
    padding-left: 0;
    &-item {
      list-style: none;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        .list-item-circle {
          background-color: $colorSuccess;
        }
      }
      &:nth-child(2) {
        .list-item-circle {
          background-color: $colorWarning;
        }
      }
      &:nth-child(3) {
        .list-item-circle {
          background-color: $colorError;
        }
      }
      &-circle {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $colorSuccess;
      }
    }
  }
  
</style>